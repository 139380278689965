export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    question: '',
    answer: '',
    faqID: 0
  }
}
