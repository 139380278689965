<template>
  <ListFrequentlyAskedQuestions :frequentlyAskedQuestionID="parseInt(frequentlyAskedQuestionID)" />
</template>

<script>
import { mapState } from 'vuex'
import ListFrequentlyAskedQuestions from '@/pages/Shared/frequentlyAskedQuestions/List'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'BoardFrequentlyAskedQuestions',

  components: {
    ListFrequentlyAskedQuestions
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { frequentlyAskedQuestions: { title: 'Frequently Asked Questions' } }
    }
  }
}
</script>

<style></style>
