<template>
  <div faq-component>
    <div class="level pl-1 pr-1">
      <div class="pt-5 level-left level-item title">
        {{ `${FAQ !== undefined && FAQ ? 'Edit ' : 'Add '}` + $t('faq.title') }}
      </div>
    </div>
    <div class="mt-6">
      <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(processSubmit)">
            <fieldset>
              <div class="form-grid">
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important; max-height: 50px !important;"
                    name="Question"
                    label="Question"
                    maxlength="8000"
                    vid="Question"
                    placeholder="Question"
                    spellcheck="true"
                    type="textarea"
                    aria-rowcount="2"
                    aria-label="Question"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="question"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Answer"
                    label="Answer"
                    maxlength="8000"
                    type="textarea"
                    aria-rowcount="6"
                    vid="Answer"
                    placeholder="Answer"
                    spellcheck="true"
                    aria-label="Answer"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="answer"
                  />
                </div>
                <div class="pt-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    {{ FAQ !== undefined && FAQ ? 'Edit ' : 'Add ' }}
                  </button>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    FAQ: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    this.reload()
  },

  watch: {
    message() {
      console.debug('message=' + this.message)
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods,

  i18n: {
    messages: {
      en: { faq: { title: 'Frequently Asked Question' } }
    }
  }
}
</script>
<style lang="scss">
.uploader {
  .rectangle {
    width: 244px;
    height: 107px;
    margin: 13px 16px 11px 53px;
    padding: 18px 25px 8px 3px;
    border-radius: 4px;
    border: #979797;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
    color: #6e7776;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
