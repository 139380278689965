import { frequentlyAskedQuestionStore } from '@/services/FrequentlyAskedQuestions/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    if (this.FAQ !== undefined && this.FAQ) {
      this.question = this.FAQ.question || ''
      this.answer = this.FAQ.answer || ''
      this.faqID = this.FAQ.frequentlyAskedQuestionID || 0
    }

    this.loading = false
  },

  open() {
    this.question = ''
    this.answer = ''
    this.faqID = 0
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (!this.question || this.question == undefined || !this.answer || this.answer == undefined) {
      notifyError('Please enter a question and answer.')
    }

    if (this.question.length >= 8000) {
      notifyError('Please shorten your question down to below 8,000 characters.')
    }

    if (this.answer.length >= 8000) {
      notifyError('Please shorten your answer down to below 8,000 characters.')
    }

    let payload = {
      frequentlyAskedQuestionID: this.faqID,
      hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : 0,
      question: this.question,
      answer: this.answer,
      sortOrder: 0
    }

    if (this.faqID === 0) {
      await frequentlyAskedQuestionStore.dispatch('addFrequentlyAskedQuestion', {
        payload,
        done: async ({ details }) => {
          console.debug('add faq=' + JSON.stringify(details))

          if (
            details &&
            details.frequentlyAskedQuestionID &&
            details.frequentlyAskedQuestionID != undefined
          ) {
            this.closeModal()
          } else {
            notifyError('There was a problem creating your frequently asked question.')
          }
        }
      })
    } else {
      await frequentlyAskedQuestionStore.dispatch('updateFrequentlyAskedQuestion', {
        payload,
        done: async ({ details }) => {
          console.debug('update faq=' + JSON.stringify(details))

          if (
            details &&
            details.frequentlyAskedQuestionID &&
            details.frequentlyAskedQuestionID != undefined
          ) {
            this.closeModal()
          } else {
            notifyError('There was a problem creating your frequently asked question.')
          }
        }
      })
    }
  },

  closeModal() {
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
  }
}
